
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { isWechat, isMobile, printLabel, getUserRole } from '@/utils/utils'
import { formatDate } from '@/utils/date'
import { carrierMatch } from '@/utils/carrier'
import BProducts from '@/components/biz/b-products.vue'
import ZButton from '@/components/base/z-button.vue'
import BCreateTicket from '@/components/biz/b-create-ticket.vue'
import Storage from '@/utils/storage'
import * as _ from 'lodash'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	},
	components: { BProducts, ZButton, BCreateTicket }
})
export default class OPSIndex extends Vue {
	public $refs!: Record<any, ElForm | ElForm[]>
	private isLoading = false
	private isLoadProducts = true
	private isEmpty = false
	private isExpressLoading = false
	private isWechat = false
	private isMobile = false
	private isWorking = false
	private canSavePackageDataStatus = [1, 2, 6]
	private packageMiniQrCode = ''
	private workOrderMessages = []
	private dialog = {
		visible: false
	}
	private ticketDialog = {
		visible: false,
		userCode: '',
		packageNum: '',
		codes: [] as any
	}
	private routerDialog = {
		visible: false,
		code: '',
		router: []
	}
	private searchForm = {
		logisticsCode: '',
		packageNumber: '',
		orderCode: ''
	}
	private countryData: any = {}
	private shelfCodeMap = {}
	private isShowShowTemporarySaveBtn = false
	@Watch('searchForm.logisticsCode')
	onInputLogisticsCode(value: string) {
		this.isEmpty = !value
		this.packageData.package_num = ''
		this.packageForms = []
	}
	private selectExpressItem = null
	private packageData = {
		is_packaging_service: null,
		user_code: '',
		user_role: -1,
		id: null,
		status_pay: 2,
		status: 2,
		express_id: null,
		country: '',
		package_num: '',
		package_attrs: [],
		products: [],
		declare_amount: 0
	}
	private showPackageInfo = true
	private showWorkOrder = true
	private workOrderForm = {
		input: '',
		fileList: []
	}
	private packageForms: any = []
	private formPlugins: any = []
	private validateWorkOrderFormRule = {
		input: [{ required: true, message: '请输入工单内容', trigger: 'blur' }]
	}
	private validateCodeRule = {
		express_code: [
			{
				required: false,
				validator: (rule: any, value: any, callback: Function) => {
					callback()
				},
				trigger: 'change'
			}
		]
	}
	private validateAttrRule = {
		weight: [{ required: true, message: '请输入重量', trigger: 'change' }],
		custom_name: [
			{
				required: true,
				message: '请输入报关名',
				validator: (rule: any, value: any, callback: Function) => {
					if (!value && this.packageData.status_pay != 1) {
						callback(new Error('请输入报关名'))
					} else {
						callback()
					}
				},
				trigger: 'blur'
			}
		],
		cargo_types: [
			{
				required: true,
				message: '请选择货物特性',
				validator: (rule: any, value: any, callback: Function) => {
					if (!value || !value.length) {
						callback(new Error('请选择货物特性'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		],
		size: [
			{
				required: true,
				validator: (rule: any, value: any, callback: Function) => {
					if (!value.length) {
						callback(new Error('请输入包裹长度'))
					} else if (!value.width) {
						callback(new Error('请输入包裹宽度'))
					} else if (!value.height) {
						callback(new Error('请输入包裹高度'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		]
	}
	private validateOpRule = {
		express_id: [
			{
				required: true,
				validator: function(rule: any, value: any, callback: Function) {
					if (!value) {
						callback(new Error('请选择线路'))
					} else {
						callback()
					}
				},
				trigger: 'change'
			}
		]
	}
	private cargoType = [
		{ label: '带电', model: 'is_battery' },
		{ label: '液体', model: 'is_liquid' },
		{ label: '敏感货', model: 'is_sensitive' },
		{ label: '食品', model: 'is_food' },
		{ label: '软包装', model: 'is_soft_pack' }
	]
	private customNames = []
	private merchantSurcharge: any = {}
	private expressList: any = {}
	private expressCompany: any = []
	private formPluginsData: any = {
		products: []
	}
	private userRoles: any = {}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	private imageList: any = []
	private cargoTypes: any = []
	private countryList: any = []
	private imageDrawerVisible = false
	private currentCursor = 0

	async getCargoTypes() {
		const { data } = await this.$axios.get('/v1/jobline/cargo-types')
		this.cargoTypes = data
	}

	async created() {
		const { logistics_code }: any = this.$route.query
		this.isWechat = isWechat()
		this.isMobile = isMobile()
		this.isWorking = Storage.get('IS_WORKING') || false
		if (this.isMobile) {
			this.showPackageInfo = false
			this.showWorkOrder = false
		}
		this.getUserRole()
		this.getCargoTypes()
		this.getCountryList()
		if (logistics_code) {
			this.searchForm.logisticsCode = logistics_code
			await this.searchPackage()
		}
		this.getExpressCompany()
		this.getState()
	}
	formatUserRole(role: number) {
		return this.userRoles[role]
	}
	getLabelTitle() {
		const suffix = this.userRoles[this.packageData.user_role].label_title_suffix
		return `${this.packageData.country}${suffix}`
	}
	getCountryIcon(country: string) {
		return this.countryData[country] ? this.countryData[country]['icon'] : ''
	}
	getCountryNameCn(country: string) {
		const item = this.countryList.find((v: any) => v.code == country)
		if (item) {
			return `[${item.code}] ${item.name_cn}`
		}
		return this.countryData[country] ? this.countryData[country]['name_cn'] : ''
	}

	toggleWorking() {
		Storage.set('IS_WORKING', this.isWorking)
	}
	getPackageStatusTag(packageAttr: any) {
		const { status, status_pay } = packageAttr
		if (status == 0) return 'canceled'
		if (status == 2) return 'created'
		if (status == 4) return 'canceling'
		if (status == 6) return 'checked'
		if (status_pay == 1) {
			return 'payed'
		}
		return ''
	}
	async getState() {
		const { data } = await this.$axios.get(
			'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
		)
		this.countryData = data
	}
	async getCountryList() {
		const { data } = await this.$axios.get(
			'https://res-cn.public.gblinker.com/gblinker/public_data/country-list.json'
		)
		this.countryList = data
	}

	readFile(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsText(file)
			reader.onload = () => {
				resolve(reader.result)
			}
			reader.onerror = err => {
				reject(err)
			}
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.searchForm.logisticsCode = result[result.length - 1]
					this.searchPackage()
				}
			})
		})
	}
	async changeShowMoreCargoTypes(packageForm: any) {
		packageForm.is_show_all = !packageForm.is_show_all
		console.log(packageForm)
	}
	selectExpress(idx: number) {
		const packageForm = this.packageForms[idx]
		const expressId = packageForm.express_id
		for (const item of this.expressList[idx]) {
			if (expressId == item.id) {
				this.$set(this.packageForms[idx], 'express', item)
				break
			}
		}
	}
	//添加包裹表单
	async addPackageForm() {
		const { package_num } = this.packageData
		const { data: packageAttr } = await this.$axios
			.post(`/v1/jobline/${package_num}/create-package-attr`)
			.catch(e => {
				return {
					data: null
				}
			})
		if (!packageAttr) return
		this.packageForms.push({
			package_id: this.packageData.id,
			package_attr_id: (packageAttr as any).id,
			is_battery: false,
			is_liquid: false,
			is_sensitive: false,
			is_food: false,
			is_soft_pack: false,
			package_attr_num: null,
			status: 2,
			status_pay: 2,
			is_expand: true,
			size: {
				height: '',
				length: '',
				width: ''
			},
			weight: '',
			express_id: null,
			custom_name: '',
			shelf_code: '',
			package_code: {
				stockout_company: '',
				stockout_code: '',
				feeder_company: '',
				feeder_code: '',
				express_code: '',
				express_company: ''
			},
			fileList: [] as any,
			products: [],
			packagingService: false,
			cargo_types: [1],
			is_show_all: false
		})
		const packageNum = this.packageData.package_num
		const length = this.packageForms.length
		const lastItem = this.packageForms[length - 2]
		let cursor = length
		if (lastItem) {
			const { package_attr_num } = lastItem
			cursor = Number(package_attr_num.split('-')[1]) + 1
		}
		this.packageForms[length - 1][
			'package_attr_num'
		] = `${packageNum}-${cursor}`
		this.canShowTemporarySaveBtn()
		await this.getSuggestedShelf()
	}

	async deletePackageForm(idx: number) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (isConfirm) {
			const packageAttr = this.packageForms[idx]
			const { data } = await this.$axios
				.delete(`/v1/jobline/package-attr/${packageAttr.package_attr_id}`)
				.catch(e => {
					return {
						data: null
					}
				})
			if (!data) return
			this.packageForms.splice(idx, 1)
			this.canShowTemporarySaveBtn()
		}
	}
	canAddPackage(): boolean {
		if (/GLP[0-9]{10}-\d+/.test(this.searchForm.logisticsCode)) {
			return false
		}

		return this.packageData.status_pay != 1 && this.packageData.status != 0
	}
	isWorkMode(idx: number): boolean {
		const { status, status_pay } = this.packageForms[idx]
		//已支付/取消
		if ([0, 1].includes(status_pay)) return true
		//不等于创建和待更新时禁止表单
		if (!this.canSavePackageDataStatus.includes(status)) return true
		return false
	}
	onRemoveImage(packageForm: any, idx: number) {
		packageForm.fileList.splice(idx, 1)
	}
	async uploadFile(packageForm: any, files: any) {
		const { file } = files
		const fileUpload = {
			name: file.name,
			percent: 0,
			file: file,
			url: '',
			path: ''
		}
		const formData = new FormData()
		formData.append('file', file)
		const uploader = this.$axios.post('/v1/jobline/upload-photo', formData, {
			timeout: 60 * 1000,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress(e: any) {
				const percentCompleted = Math.round((e.loaded * 100) / e.total)
				fileUpload.percent = percentCompleted
			},
			timeoutErrorMessage: '网络环境较差，上传图片超时'
		})

		packageForm.fileList.push(fileUpload)
		const { data } = await uploader.catch(() => {
			packageForm.fileList.forEach((el: any, index: number) => {
				if (el.name == fileUpload.name) {
					packageForm.fileList.splice(index, 1)
				}
			})
			throw new Error('error')
		})
		const { url, path } = data[0]
		fileUpload.url = `${url}?image_process=quality,q_50`
		fileUpload.path = path
	}

	async sendWorkOrder() {
		const { package_num } = this.packageData
		const isValidate = await (this.$refs['workorder-form'] as ElForm)
			.validate()
			.catch(() => false)
		if (!isValidate) return
		const { data } = await this.$axios
			.post(`/v1/jobline/package-note/${package_num}`, {
				message: this.workOrderForm.input,
				photos_url: this.workOrderForm.fileList.map((el: any) => el.path)
			})
			.catch(() => {
				return {
					data: null
				}
			})
		if (!data) return false
		this.$message.success('添加工单成功')
		this.getPackageWorkOrder()
		this.workOrderForm.input = ''
		this.workOrderForm.fileList = []
	}
	// 登记包裹单号
	async signPackageCode() {
		const packageCodes = []
		for (const packageForm of this.packageForms) {
			const { status_pay, status } = packageForm
			//未支付
			if ([0].includes(status_pay)) continue
			//已取消/待取消
			if ([0, 4].includes(status)) continue
			packageCodes.push({
				...packageForm.package_code,
				package_attr_num: packageForm.package_attr_num
			})
		}
		const putData = {
			package_codes: packageCodes
		}
		const { data } = await this.$axios.post('/v1/jobline/package-code', putData)

		if (data) {
			this.$message.success('保存成功')
		}
	}
	//登记作业信息
	async savePackage() {
		const { package_num, status_pay } = this.packageData
		//已退款
		if (status_pay === 0) {
			return
		}
		//已支付
		if (status_pay === 1) {
			await this.signPackageCode()
			return
		}

		const isConfirm = await this.$confirm('确认提交包裹测量信息？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		//验证获取线路表单
		const validateAttrHandler: Promise<boolean>[] = []
		;(this.$refs['package-attr-form'] as ElForm[]).map(async (form, idx) => {
			const needValidate = this.isWorkMode(idx)
			if (needValidate) {
				validateAttrHandler.push(Promise.resolve(true))
				return
			}
			const isValidate = form.validate().catch(() => false)
			validateAttrHandler.push(isValidate)
		})
		const isValidateAttr = await Promise.all(validateAttrHandler)
		const errorAttrFormIdx = isValidateAttr.indexOf(false)

		if (errorAttrFormIdx > -1) {
			const elem = document.getElementById(
				`package-attr-form-${errorAttrFormIdx}`
			) as HTMLElement
			scrollTo(0, elem.getBoundingClientRect().top + window.scrollY)
		}
		if (errorAttrFormIdx > -1) return
		//验证线路选择表单
		const validateOpHandler: Promise<boolean>[] = []
		;(this.$refs?.form as ElForm[]).map(async form => {
			const isValidate = form.validate().catch(() => false)
			validateOpHandler.push(isValidate)
		})
		const isValidateOp = await Promise.all(validateOpHandler)
		const errorOpFormIdx = isValidateOp.indexOf(false)
		if (errorOpFormIdx > -1) {
			const elem = document.getElementById(
				`package-op-form-${errorOpFormIdx}`
			) as HTMLElement
			scrollTo(0, elem.offsetTop)
		}
		if (errorOpFormIdx > -1) return
		const { logisticsCode } = this.searchForm
		const packageFormData = this.getPackageFormData()
		if (!packageFormData) return
		const checkPlugins = this.validatePluginsData(packageFormData)
		if (!checkPlugins) return
		const putData = {
			package_info: [...packageFormData],
			search_code: logisticsCode
		}
		if (!putData.package_info.length) {
			return
		}
		const endpoint = `/v1/jobline/${package_num}/sign-package`
		const { data } = await this.$axios.put(endpoint, putData)
		if (data) {
			this.$message.success('保存成功')
			this.isShowShowTemporarySaveBtn = false
			// this.resetForm()
		}
	}
	//获取工单
	async getPackageWorkOrder() {
		const { package_num } = this.packageData
		const { data } = await this.$axios.get(
			`/v1/jobline/package-note/${package_num}`
		)
		if (this.packageData.declare_amount >= 3000) {
			const item = {
				created: Math.floor(new Date().getTime() / 1000),
				employer_name: '系统消息',
				id: 0,
				message: '此单为高货值订单;保价￥' + this.packageData.declare_amount,
				photos: [],
				user: { name: null },
				user_type: 'operator'
			}
			data.unshift(item)
		}
		this.workOrderMessages = data
	}
	//获取建议货架
	async getSuggestedShelf() {
		const package_attr_nums = this.packageForms.map(
			(el: any) => el.package_attr_num
		)
		const { data } = await this.$axios.post('/v1/jobline/package-shelf', {
			package_attr_nums: package_attr_nums
		})
		this.$set(this, 'shelfCodeMap', data)
	}
	getLabelUrl(packageNum: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${packageNum}?title=${this.getLabelTitle()}`
	}
	printLabel(packageNum: string) {
		return printLabel(packageNum, this.getLabelTitle())
	}
	getVoiceUrl(packageNum: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/package-gift/generate/mini-code/${packageNum}`
	}
	async showPackageRouter(packageForm: any) {
		const { package_attr_id, package_attr_num } = packageForm
		if (!package_attr_id) return false
		const { data } = await this.$axios.get(
			`/v1/jobline/${package_attr_id}/get-tracking`
		)

		this.routerDialog.visible = true
		this.routerDialog.code = package_attr_num
		this.routerDialog.router = data
	}

	async refreshPhotos(packageForm: any) {
		const { data } = await this.$axios.get(
			`/v1/jobline/package-attr/resource/${packageForm.package_attr_id}`
		)

		if (data) {
			packageForm.fileList = data
		}

		this.$message.success('加载图片成功')
	}

	async resetForm() {
		for (const item of this.packageForms) {
			item['package_id'] = ''
			item['package_attr_num'] = ''
			item['custom_name'] = ''
			item['shelf_code'] = ''
			item['package_attr_id'] = ''
			item['is_battery'] = false
			item['is_liquid'] = false
			item['is_sensitive'] = false
			item['is_food'] = false
			item['is_soft_pack'] = false
			item['is_expand'] = true
			item['size'] = {
				height: '',
				length: '',
				width: ''
			}
			item['weight'] = ''
			item['express_id'] = null
			item['fileList'] = []
			item.package_code = {
				stockout_company: '',
				stockout_code: '',
				feeder_company: '',
				feeder_code: '',
				express_code: '',
				express_company: ''
			}
			item['packagingService'] = false
			item['is_show_all'] = false
			item['cargo_types'] = []
		}
	}
	async getExpressCompany() {
		const { data } = await this.$axios.get('v1/jobline/express-company', {})

		this.expressCompany = data
	}
	async showPackageMiniCode() {
		const { package_num } = this.packageData
		if (!this.packageMiniQrCode) {
			const { data } = await this.$axios.get(
				`/v1/jobline/${package_num}/mini-qrcode`
			)
			this.packageMiniQrCode = data
		}
		this.dialog.visible = true
	}
	async getExpress(cursor: number, reset = true) {
		const form = this.$refs['package-attr-form'] as ElForm[]
		const packageAttrForm = form.find(
			(item: any) => item.$attrs.id == `package-attr-form-${cursor}`
		)

		const isValidate = await packageAttrForm?.validate().catch(() => false)
		if (!isValidate && this.packageData.status_pay != 1) return

		const packageForm = this.packageForms[cursor]
		if (reset) {
			this.packageForms[cursor].express_id = null
			this.packageForms[cursor].express = null
			this.expressList[cursor] = []
		}
		const types = this.cargoTypes.filter((v: { value: any }) =>
			packageForm.cargo_types.includes(v.value)
		)
		const type = {
			is_battery: 0,
			is_liquid: 0,
			is_sensitive: 0,
			is_food: 0
		}
		for (const item of types) {
			type.is_battery = type.is_battery || item.is_battery
			type.is_liquid = type.is_liquid || item.is_liquid
			type.is_sensitive = type.is_sensitive || item.is_sensitive
			type.is_food = type.is_food || item.is_food
		}
		Object.assign(packageForm, type)

		const { size, weight } = packageForm
		const { width, height, length } = size
		const postData = {
			package_id: this.packageData.id,
			country: this.packageData.country,
			is_battery: Number(packageForm.is_battery),
			is_liquid: Number(packageForm.is_liquid),
			is_sensitive: Number(packageForm.is_sensitive),
			is_soft_pack: Number(packageForm.is_soft_pack),
			is_food: Number(packageForm.is_food),
			weight: Math.round(Number(weight) * 1000),
			size_width: Number(width),
			size_length: Number(length),
			size_height: Number(height),
			cargo_types: packageForm.cargo_types
		}
		const { data } = await this.$axios
			.post('v1/jobline/usable-express', {
				...postData
			})
			.finally(() => (this.isExpressLoading = false))

		if (data.length == 0) {
			this.$message.error('无匹配线路')
		}
		this.$set(this.expressList, cursor, data)
		const selectedExpressId =
			(this.packageData.package_attrs[0] as any)?.express_id || null

		if (cursor == 0 && !selectedExpressId) {
			for (const item of data) {
				if (item.id == this.packageData.express_id) {
					this.$set(this.packageForms[cursor], 'express', item)
					this.packageForms[cursor].express_id = this.packageData.express_id
					break
				}
			}
		}
		this.$forceUpdate()
	}
	emitInputCode($index: number, row: any, codeName: string) {
		const carrier = carrierMatch(row[codeName])
		if (carrier) {
			const companyCodeName = codeName.replace(/code/, 'company')
			this.$set(
				this.packageForms[$index].package_code,
				companyCodeName,
				carrier.value
			)
		}
	}
	getPackageFormData() {
		const packageForms = this.packageForms
		const formData = []
		for (const packageForm of packageForms) {
			const { size, weight, status } = packageForm
			//登记包裹只登记2->已创建(新增)，1->已作业(更新)，其余的需要过滤
			if (!this.canSavePackageDataStatus.includes(Number(status))) continue
			const { width, height, length } = size
			const filePaths: string[] = []
			packageForm.fileList.map((item: any) => {
				filePaths.push(item.path)
			})
			formData.push({
				package_id: this.packageData.id,
				package_attr_num: packageForm.package_attr_num,
				express_id: Number(packageForm.express_id),
				is_battery: Number(packageForm.is_battery),
				is_liquid: Number(packageForm.is_liquid),
				custom_name: packageForm.custom_name.trim() || '',
				shelf_code: packageForm.shelf_code || '',
				is_sensitive: Number(packageForm.is_sensitive),
				is_soft_pack: Number(packageForm.is_soft_pack),
				is_food: Number(packageForm.is_food),
				weight: Number(weight) * 1000,
				size_width: Number(width),
				size_length: Number(length),
				size_height: Number(height),
				photos_url: filePaths,
				packaging_service: Number(packageForm.packagingService),
				cargo_types: packageForm.cargo_types
			})
		}
		return formData
	}
	getRateWeight(express: any, rateWeight: number) {
		if (express) {
			return (express.rate_weight / 1000).toFixed(3) + '千克'
		}
		if (rateWeight) {
			return (rateWeight / 1000).toFixed(3) + '千克'
		}
		return '-'
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async searchPackage() {
		let { logisticsCode } = this.searchForm
		logisticsCode = logisticsCode.trim()

		if (!logisticsCode) {
			this.$message.warning('请输入需要查询的国内快递单号')
			return
		}
		if (this.isWorking && /^GLP\d+-\d+/.test(logisticsCode)) {
			const codeMatch = logisticsCode.match(/GLP\d+/)
			const packageNum = codeMatch ? codeMatch[0] : ''
			this.searchForm.logisticsCode = packageNum
			logisticsCode = packageNum
		}

		if (this.isLoading) {
			return
		}
		this.isLoading = true
		this.packageMiniQrCode = ''
		this.packageForms = []
		this.customNames = []
		const { data } = await this.$axios
			.get(`/v1/jobline/${logisticsCode}/package-info`, {})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		this.$router
			.push({
				query: {
					logistics_code: logisticsCode
				}
			})
			.catch(err => err)
		if (!data) {
			this.isEmpty = true
			this.packageData.package_num = ''
			return
		}
		await this.setFormPlugins(data.country)
		this.$set(this, 'packageData', data)
		const packageAttributes = data.package_attrs || []
		for (const item of packageAttributes) {
			const packageForm: any = {}
			packageForm.is_battery = Boolean(item.is_battery)
			packageForm.is_liquid = Boolean(item.is_liquid)
			packageForm.is_sensitive = Boolean(item.is_sensitive)
			packageForm.is_food = Boolean(item.is_food)
			packageForm.is_soft_pack = Boolean(item.is_soft_pack)
			packageForm.custom_name = item.custom_name || ''
			packageForm.shelf_code = item.shelf_code || ''
			packageForm.size = {
				height: item.height,
				length: item.length,
				width: item.width
			}
			packageForm.package_attr_num = item.package_attr_num
			packageForm.is_expand = item.status != 0
			packageForm.package_attr_id = item.id
			packageForm.weight = item.weight
				? String((item.weight / 1000).toFixed(3))
				: ''
			packageForm.fileList = item.photos
			packageForm.status = item.status
			packageForm.status_pay = item.status_pay
			packageForm.rate_weight = item.rate_weight
			packageForm.warehouse_record_code = item.warehouse_record_code
			packageForm.box_code = item.box_code
			packageForm.warehouse_record_time = item.warehouse_record_time
			packageForm.express_id = item.express_id
				? item.express_id
				: data.express_id
			packageForm.package_id = data.id
			packageForm.package_code = {
				feeder_code: item.package_code_feeder_code
					? item.package_code_feeder_code.trim()
					: '',
				feeder_company: item.package_code_feeder_company,
				express_code: item.package_code_express_code
					? item.package_code_express_code.trim()
					: '',
				express_company: item.package_code_express_company
			}
			packageForm.products = item.products
			packageForm.packagingService = this.packageData.is_packaging_service
			packageForm.cargo_types = item.cargo_types || []
			packageForm.is_show_all = false
			this.packageForms.push(packageForm)
		}
		if (!this.packageForms.length) {
			this.addPackageForm()
		} else {
			this.$nextTick(() => {
				this.packageForms.forEach(async (a: any, idx: number) => {
					if (a.status) {
						await this.getExpress(idx, false)
					}
				})
			})
		}
		await this.getPackageWorkOrder()
		await this.getSuggestedShelf()
		this.isEmpty = false
		if (data.status_pay == 2) {
			this.getAllPackageImages()
			this.getUserCustomNames(data.user_id)
			this.canShowTemporarySaveBtn()
		}
	}

	async getUserCustomNames(userId: number) {
		const { data } = await this.$axios
			.get(`v1/jobline/user-custom-names/${userId}`)
			.catch(e => {
				return { data: [] }
			})
		this.customNames = data.filter(
			(item: any) => item.value && item.value.trim()
		)
		return data
	}
	setMinimumSize(cursor: number) {
		const packageForm = this.packageForms[cursor]
		const { size } = packageForm
		size.length = 16
		size.width = 10
		size.height = 1
		const elem = document.getElementById(`size-length-${cursor}`) as HTMLElement
		elem.dispatchEvent(new Event('blur'))
	}

	inputCustomName(cursor: number, customName: string) {
		const packageForm = this.packageForms[cursor]
		packageForm.custom_name = customName
		const elem = document.getElementById(`custom-name-${cursor}`) as HTMLElement
		elem.dispatchEvent(new Event('blur'))
	}

	async setFormPlugins(country: string) {
		if (!this.countryData[country]) {
			await this.getState()
		}
		const countryData: any = this.countryData[country] || {}
		const plugins = countryData['miniprogram_order_plugins'] || []
		this.formPlugins = plugins.reduce(
			(a: any, v: any) => ({ ...a, [v.name]: v }),
			{}
		)
	}

	changeProductsData(data: any[], cursor: number) {
		this.$set(this.packageForms[cursor], 'products', data)
		const names = data.map((item: any) => item.name_cn)
		this.$set(this.packageForms[cursor], 'custom_name', names.join('/'))
	}
	validatePluginsData(packageFormData: any[], showErr = true): boolean {
		const pluginData = []
		const packageForms = this.packageForms
		for (let index = 0; index < packageForms.length; index++) {
			const packageForm = packageForms[index]
			if (packageForm.status == 0) {
				continue
			}
			const pkgIndex = _.findIndex(packageFormData, v => {
				return v.package_attr_num == packageForm.package_attr_num
			})
			for (const key in this.formPlugins) {
				const plugin = this.formPlugins[key]
				const { form_name, required } = plugin
				pluginData[form_name] = (packageForm as any)[form_name] || []
				if (required && !pluginData[form_name].length && showErr) {
					this.$message.error(
						`${packageForm.package_attr_num} 清关信息不能为空!`
					)
					const elem = document.getElementById(
						`package-attr-form-${pkgIndex}`
					) as HTMLElement
					scrollTo(0, elem.getBoundingClientRect().top + window.scrollY)
					return false
				}
				this.$set(packageFormData[pkgIndex], form_name, pluginData[form_name])
			}
		}
		return true
	}
	canShowTemporarySaveBtn(): boolean {
		if (this.packageData.status_pay !== 2) {
			this.isShowShowTemporarySaveBtn = false
			return false
		}
		const package_attrs = this.packageForms
		for (const item of package_attrs) {
			if ([2, 6].includes(item.status)) {
				this.isShowShowTemporarySaveBtn = true
				return true
			}
		}
		this.isShowShowTemporarySaveBtn = false
		return false
	}
	async savePackageTemporary() {
		const isConfirm = await this.$confirm(
			'确认提交包裹信息吗？只有已创建状态的包裹才能保存。',
			'提示',
			{
				confirmButtonText: '确认',
				cancelButtonText: '取消'
			}
		).catch(() => false)
		if (!isConfirm) return false
		const { logisticsCode } = this.searchForm
		const packageFormData = this.getPackageFormData()
		if (!packageFormData) return
		this.validatePluginsData(packageFormData, false)
		const putData = {
			package_info: [...packageFormData],
			search_code: logisticsCode
		}
		if (!putData.package_info.length) {
			return
		}
		const { package_num } = this.packageData
		const endpoint = `/v1/jobline/${package_num}/temporary-sign-package`
		const { data } = await this.$axios.put(endpoint, putData)
		if (data) {
			this.$message.success('保存成功')
			await this.searchPackage()
		}
	}
	async openProductBarCodeDialog() {
		window.open(
			`/ops/barcode?package_num=${this.packageData.package_num}`,
			'_blank'
		)
	}
	async openTicketDialog() {
		const { logistics_code = '', user_code, package_num = '' } = this
			.packageData as any
		this.ticketDialog.userCode = user_code
		this.ticketDialog.packageNum = package_num
		if (logistics_code) {
			this.ticketDialog.codes.push(logistics_code)
		}
		this.ticketDialog.codes.push(package_num)
		this.ticketDialog.visible = true
	}
	closeTicketDialog() {
		this.getPackageWorkOrder()
		this.ticketDialog = {
			visible: false,
			userCode: '',
			packageNum: '',
			codes: []
		}
	}
	// 获取合包前的包裹图片
	async getAllPackageImages() {
		const packageId = this.packageData.id
		const { data } = await this.$axios.get(
			`/v1/jobline/packages/consolidation/${packageId}/images`
		)
		if (!data.length) {
			return
		}
		this.imageList = []
		for (const item of data) {
			for (const v of item.images) {
				this.imageList.push({
					...v,
					selected: false
				})
			}
		}
	}

	showPackageImages(cursor: number) {
		const packageForm = this.packageForms[cursor]
		const { fileList } = packageForm
		const images = fileList.map((v: any) => v.path)
		for (const item of this.imageList) {
			item.selected = images.includes(item.path)
		}
		this.currentCursor = cursor
		this.imageDrawerVisible = true
	}
	async onChange(item: any) {
		item.selected = !item.selected
		this.chooseImages(item)
	}
	async chooseImages(item: any) {
		const packageForm = this.packageForms[this.currentCursor]
		const { fileList } = packageForm
		if (item.selected) {
			item.selected = !item.selected
			fileList.forEach((el: any, index: number) => {
				if (el.path == item.path) {
					fileList.splice(index, 1)
				}
			})
			return
		}
		item.selected = !item.selected
		fileList.push(item)
	}
}

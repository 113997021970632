
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
	name: 'BProducts'
})
export default class BProducts extends Vue {
	@Prop({ data: {} } as any) private data?: {}
	private productForm: any = {
		products: []
	}
	private statusPay = 2
	private packageProducts = []
	private validateAttrRule = {
		name_cn: [
			{
				required: true,
				message: '请输入',
				trigger: 'change'
			},
			{ required: true, message: '请输入', trigger: 'blur' }
		],
		price: [
			{ required: true, message: '请输入', trigger: 'blur' },
			{ required: true, message: '请输入', trigger: 'change' }
		],
		quantity: [
			{ required: true, message: '请输入', trigger: 'blur' },
			{ required: true, message: '请输入', trigger: 'change' }
		]
	}
	created() {
		const data: any = this.data || {}
		this.statusPay = data.statusPay || 2
		this.productForm['products'] = data.packageAttrProducts || []
		this.packageProducts = data.packageProducts || []
	}

	addPackageAttrProductForm() {
		const productForm: any = this.productForm
		productForm.products.push({
			name_cn: '',
			price: undefined,
			quantity: undefined
		})
	}
	async removePackageAttrProductForm({ $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.productForm.products.splice($index, 1)
		this.getPackageRate()
	}
	handleSelectProduct(event: any, { row }: any) {
		const product: any =
			this.packageProducts.filter((v: any) => v.name_cn === event)[0] || {}

		row.name_cn = product['name_cn'] || event
		row.price = product['price'] || undefined
		row.quantity = product['quantity'] || undefined
		this.getPackageRate()
	}

	async getPackageRate() {
		this.$emit('changeProductsData', this.productForm.products)
	}
}

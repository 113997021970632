
import { ElForm } from 'element-ui/types/form'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
	name: 'BCreateTicket'
})
export default class BCreateTicket extends Vue {
	@Prop({ userCode: '' } as any) private userCode?: ''
	@Prop({ packageNum: '' } as any) private packageNum?: ''
	@Prop({ groupId: '' } as any) private groupId?: ''
	@Prop({ codes: [] } as any) private codes?: []

	public $refs!: Record<any, ElForm | ElForm[]>
	private userInfo = {
		mobile: ''
	}
	private taskGroup = [
		{
			key: '62909f97c7a7f6003f30607d',
			name: '早晚班客服对接',
			selected: false
		},
		{
			key: '619a0a03d30d00003f845177',
			name: '仓库作业',
			selected: false
		},
		{
			key: '619a09f5704738003f951d0e',
			name: '客服跟单',
			selected: true
		},
		{
			key: '61f2465c383235003fdb2824',
			name: '异常件',
			selected: true
		}
	]

	private exceptionType = [
		{
			key: 'good_exception',
			name: '包裹物品异常待核实',
			selected: false
		},
		{
			key: 'address_exception',
			name: '收件人地址异常待核实',
			selected: false
		},
		{
			key: 'express_exception',
			name: '包裹物品超出线路限制',
			selected: true
		},
		{
			key: 'out_delivery',
			name: '收件地址超区',
			selected: false
		}
	]

	private form = {
		user_code: '',
		package_num: '',
		is_notify: false,
		group: '619a09f5704738003f951d0e',
		exception_type: '',
		phone_prefix: '+86',
		mobile: '',
		content: '',
		numbers: '',
		notify_method: ['sms', 'office_account']
	}

	private validateForm = {
		content: [
			{
				required: true,
				message: '请输入工单内容',
				trigger: 'blur'
			}
		],
		group: [
			{
				required: true,
				message: '请选择工单分组',
				trigger: 'change'
			}
		],
		exception_type: [
			{
				required: true,
				message: '请选择异常类别',
				trigger: 'change'
			}
		]
	}
	created() {
		if (this.userCode) {
			this.form.user_code = this.userCode
			this.onUserCodeInput(this.userCode)
		}
		if (this.groupId) this.form.group = this.groupId
		if (this.packageNum) this.form.package_num = this.packageNum
		if (this.codes?.length) this.form.numbers = this.codes.join('\n')
	}

	@Watch('userCode')
	onUserCodeChange(value: any) {
		this.userCode = value
		this.form.user_code = value
		this.onUserCodeInput(value)
	}

	@Watch('codes')
	onCodesChange(codes: any) {
		this.codes = codes
		if (this.codes?.length) this.form.numbers = this.codes.join('\n')
	}

	onPhonePrefixChange(val: string) {
		if (!val) {
			this.$confirm('选择其他时请输入完整区号和手机号如: +8528582205', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).catch()
		}
	}
	async onUserCodeInput(val: string) {
		if (!val) {
			this.userInfo = {
				mobile: ''
			}
			this.form.mobile = ''
			return
		}
		if (val.length < 6) {
			return
		}
		const userInfo = await this.getUserInfo(val)
		if (!userInfo) {
			this.userInfo = {
				mobile: ''
			}
			return
		}
		this.userInfo = userInfo
		this.form.mobile = userInfo.mobile
		if (
			userInfo.mobile.length == 11 &&
			/^1[3-9]{1}[0-9]{9}/.test(userInfo.mobile)
		) {
			this.form.phone_prefix = '+86'
			return
		}
		this.form.phone_prefix = ''
	}
	async getUserInfo(userCode: string) {
		const { data } = await this.$axios
			.get('/v1/jobline/find/user', {
				params: {
					user_code: userCode
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})
		return data?.user
	}

	async createTicket() {
		const {
			user_code,
			content,
			numbers,
			notify_method,
			group,
			exception_type,
			package_num
		} = this.form
		let { phone_prefix, mobile } = this.form
		if (this.form.is_notify && notify_method.includes('sms') && !mobile) {
			this.$message.error('请输入用户手机号!')
			return
		}
		const isValidate = await (this.$refs['ticket-form'] as ElForm)
			.validate()
			.catch(() => false)
		if (!isValidate) return
		if (phone_prefix == '+86') {
			phone_prefix = ''
		}
		if (user_code && !mobile) {
			mobile = this.userInfo?.mobile
		}
		const codes = Array.from(
			new Set(
				numbers
					.trim()
					.split(/[;,\n]/)
					.map(item => item.trim().replace(/[\s\t]/g, ''))
			)
		)
		const postData = {
			package_num: package_num,
			user_code: user_code,
			content,
			codes: codes.join(','),
			notify_config: null,
			group,
			exception_type
		}
		let notify_config
		if (this.form.is_notify && mobile && notify_method.includes('sms')) {
			notify_config = {
				user: {
					sms: true,
					mobile: `${phone_prefix}${mobile}`,
					office_account: true
				}
			}
			Object.assign(postData, {
				notify_config
			})
		}
		const { data } = await this.$axios
			.post('/v1/jobline/work-order', postData)
			.catch(() => {
				return {
					data: null
				}
			})
		if (!data) return false
		this.$message.success('新建工单成功')
		this.$emit('createTicketSuccess')
	}
}

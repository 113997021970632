var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "div",
        { staticClass: "container search-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "search-form",
              staticClass: "search-form",
              attrs: { inline: true, model: _vm.searchForm },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return (() => {}).apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "入仓单号/包裹单号" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "国内快递单号/包裹单号",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchPackage.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchForm.logisticsCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchForm,
                          "logisticsCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchForm.logisticsCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "z-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchPackage },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.isWechat
                    ? _c(
                        "z-button",
                        {
                          attrs: { type: "default" },
                          on: { click: _vm.scanBarcode },
                        },
                        [_vm._v("扫码")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl" },
            [
              _c("el-switch", {
                attrs: { "active-text": "作业模式" },
                on: { change: _vm.toggleWorking },
                model: {
                  value: _vm.isWorking,
                  callback: function ($$v) {
                    _vm.isWorking = $$v
                  },
                  expression: "isWorking",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.packageData.package_num
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, xs: 24, sm: 10, md: 9 } },
                    [
                      _c("el-row", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "container info-card" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "info-header",
                                on: {
                                  click: function ($event) {
                                    _vm.isMobile &&
                                      (_vm.showWorkOrder = !_vm.showWorkOrder)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-icon_remark",
                                      },
                                    }),
                                  ]
                                ),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v("工单备注"),
                                ]),
                                _vm.isMobile
                                  ? _c("i", {
                                      staticClass:
                                        "toggle-icon el-icon-arrow-right",
                                      class: { expand: !_vm.showWorkOrder },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm.showWorkOrder
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "info-body" },
                                    [
                                      _vm.workOrderMessages.length
                                        ? _vm._l(
                                            _vm.workOrderMessages,
                                            function (item, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: idx,
                                                  staticClass: "info-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "message-item",
                                                      class: {
                                                        "is-customer":
                                                          item.user_type ==
                                                          "client",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "message-body",
                                                        },
                                                        [
                                                          item.user_type ==
                                                          "teambition"
                                                            ? _c(
                                                                "p",
                                                                [
                                                                  _c(
                                                                    "el-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        content:
                                                                          "点击查看详情",
                                                                        placement:
                                                                          "top",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-link",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              href: item.link,
                                                                              type: "primary",
                                                                              target:
                                                                                "_blank",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.message
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.message
                                                                  )
                                                                ),
                                                              ]),
                                                          item.photos.length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "attachment-image",
                                                                },
                                                                _vm._l(
                                                                  item.photos,
                                                                  function (
                                                                    file,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "el-image",
                                                                      {
                                                                        key: idx,
                                                                        attrs: {
                                                                          "preview-src-list":
                                                                            item.photos.map(
                                                                              (
                                                                                e
                                                                              ) =>
                                                                                e.url
                                                                            ),
                                                                          src: file.url,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "message-footer",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "message-author",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.user_type ==
                                                                      "client"
                                                                      ? "用户"
                                                                      : item.employer_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "message-time",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatDate"
                                                                    )(
                                                                      item.created
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "empty-workorder" },
                                            [_vm._v("暂无工单备注")]
                                          ),
                                    ],
                                    2
                                  ),
                                  _c("div", { staticClass: "info-footer" }, [
                                    _c(
                                      "div",
                                      { staticClass: "workorder-form" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "workorder-form",
                                            attrs: {
                                              rules:
                                                _vm.validateWorkOrderFormRule,
                                              model: _vm.workOrderForm,
                                              "label-width": "0px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass:
                                                  "workorder-form-body",
                                                attrs: { prop: "input" },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "textarea",
                                                    autosize: {
                                                      minRows: 2,
                                                      maxRows: 6,
                                                    },
                                                    placeholder:
                                                      "请输入工单内容",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.workOrderForm.input,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.workOrderForm,
                                                        "input",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "workOrderForm.input",
                                                  },
                                                }),
                                                _c(
                                                  "el-upload",
                                                  {
                                                    staticClass: "upload-demo",
                                                    attrs: {
                                                      action: "#",
                                                      multiple: true,
                                                      accept:
                                                        "image/x-png,image/gif,image/jpeg,image/svg",
                                                      "show-file-list": false,
                                                      "http-request": (file) =>
                                                        _vm.uploadFile(
                                                          _vm.workOrderForm,
                                                          file
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticClass:
                                                          "icon attachment",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      },
                                                      [
                                                        _c("use", {
                                                          attrs: {
                                                            "xlink:href":
                                                              "#icon-icon_btn_annex",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "send-workorder",
                                                    attrs: {
                                                      size: "medium",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: _vm.sendWorkOrder,
                                                    },
                                                  },
                                                  [_vm._v("发送")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "workorder-form-file",
                                          },
                                          _vm._l(
                                            _vm.workOrderForm.fileList,
                                            function (item, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: idx,
                                                  staticClass: "file-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "top" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "left" },
                                                        [
                                                          _c("el-image", {
                                                            staticClass: "img",
                                                            attrs: {
                                                              "v:if":
                                                                "item.url",
                                                              "preview-src-list":
                                                                _vm.workOrderForm.fileList.map(
                                                                  (e) => e.url
                                                                ),
                                                              src: item.url,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "right",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-close",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onRemoveImage(
                                                                  _vm.workOrderForm,
                                                                  idx
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "bottom" },
                                                    [
                                                      item.percent != 100
                                                        ? _c("el-progress", {
                                                            attrs: {
                                                              percentage:
                                                                item.percent ||
                                                                0,
                                                              "show-text": false,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("el-row", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "container info-card package-info" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "info-header",
                                on: {
                                  click: function ($event) {
                                    _vm.isMobile &&
                                      (_vm.showPackageInfo =
                                        !_vm.showPackageInfo)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-icon_order",
                                      },
                                    }),
                                  ]
                                ),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v("订单信息"),
                                ]),
                                _vm.isMobile
                                  ? _c("i", {
                                      staticClass:
                                        "toggle-icon el-icon-arrow-right",
                                      class: { expand: !_vm.showPackageInfo },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm.showPackageInfo
                              ? _c("div", { staticClass: "info-body" }, [
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("操作")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "info-item-content ops-btn",
                                      },
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              href:
                                                "/user/order?user_code=" +
                                                _vm.packageData.user_code,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  round: "",
                                                  plain: "",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("去下单")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              href:
                                                "/user/packages?user_code=" +
                                                _vm.packageData.user_code,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  round: "",
                                                  plain: "",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("包裹列表")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              underline: false,
                                              href: _vm.getVoiceUrl(
                                                _vm.packageData.package_num
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  round: "",
                                                  plain: "",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("录音二维码")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-link",
                                          { attrs: { underline: false } },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  round: "",
                                                  plain: "",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: _vm.openTicketDialog,
                                                },
                                              },
                                              [_vm._v("新建工单")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("用户编码")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "info-item-content user-code",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.packageData.user_code)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("订单单号")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.packageData.package_num
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("客户昵称")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.packageData.user_info
                                                .nickName || "-"
                                            ) +
                                            " "
                                        ),
                                        _vm.packageData.is_packaging_service
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass:
                                                  "item icon-packing",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "该用户需要收取打包费",
                                                  placement: "right-start",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-receive",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "info-item" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "info-item-label" },
                                        [_vm._v("客户类型")]
                                      ),
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: _vm.formatUserRole(
                                              _vm.packageData.user_role
                                            ).tag_type,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatUserRole(
                                                _vm.packageData.user_role
                                              ).name
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("微信头像")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm.packageData.user_info.avatarUrl
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              attrs: {
                                                src: _vm.packageData.user_info
                                                  .avatarUrl,
                                              },
                                            })
                                          : _c("p", [_vm._v("-")]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("国内快递单号")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.packageData.logistics_code
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("收件国家/地区")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "info-item-content receiver-country",
                                      },
                                      [
                                        _vm.getCountryIcon(
                                          _vm.packageData.country
                                        )
                                          ? _c("img", {
                                              staticClass: "country-icon",
                                              attrs: {
                                                src: _vm.getCountryIcon(
                                                  _vm.packageData.country
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getCountryNameCn(
                                                _vm.packageData.country
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("收件人信息")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "info-item-content receiver-info",
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.packageData.firstname
                                              ) +
                                              " " +
                                              _vm._s(_vm.packageData.lastname) +
                                              " "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.packageData.mobile || "-"
                                            )
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.packageData.address1) +
                                              " " +
                                              _vm._s(_vm.packageData.address2) +
                                              "," +
                                              _vm._s(_vm.packageData.city) +
                                              "," +
                                              _vm._s(_vm.packageData.state) +
                                              " " +
                                              _vm._s(_vm.packageData.zipcode) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("申报金额")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " ¥" +
                                            _vm._s(
                                              _vm.packageData.declare_amount
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("客户下单线路")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.packageData.express_title ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("订单状态")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.packageData.status_label
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("下单时间")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                _vm.packageData.created
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "info-item" }, [
                                    _c(
                                      "label",
                                      { staticClass: "info-item-label" },
                                      [_vm._v("详情")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "info-item-content" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "el-button--text",
                                            attrs: {
                                              href: "javascript:void(0)",
                                            },
                                            on: {
                                              click: _vm.showPackageMiniCode,
                                            },
                                          },
                                          [_vm._v("查看小程序二维码")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16, xs: 24, sm: 14, md: 15 } },
                    [
                      _vm._l(_vm.packageForms, function (packageForm, idx) {
                        return _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "container info-card",
                            class: _vm.getPackageStatusTag(packageForm),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "info-header" },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-icon_package",
                                      },
                                    }),
                                  ]
                                ),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        packageForm.package_attr_num ||
                                          "包裹信息"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "print-package-btn",
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.getLabelUrl(
                                        packageForm.package_attr_num
                                      ),
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-printer" })]
                                ),
                                packageForm.package_attr_id
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "print-package-btn",
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showPackageRouter(
                                              packageForm
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-s-promotion",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-badge",
                                  {
                                    staticClass: "item",
                                    attrs: { value: "beta" },
                                  },
                                  [
                                    packageForm.package_attr_id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "print-package-btn",
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.printLabel(
                                                  packageForm.package_attr_num
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-printer",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "package-form-header-info" },
                                  [
                                    packageForm.warehouse_record_time
                                      ? _c(
                                          "span",
                                          { staticClass: "outbound-time" },
                                          [
                                            _vm._v(
                                              "出库时间：" +
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    packageForm.warehouse_record_time
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isWorkMode(idx) && idx != 0
                                      ? _c(
                                          "i",
                                          {
                                            staticClass:
                                              "delete-package-btn el-icon-delete",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deletePackageForm(
                                                  idx
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    packageForm.status == 0
                                      ? _c("i", {
                                          staticClass:
                                            "expand-icon el-icon-arrow-right",
                                          class: {
                                            expand: !packageForm.is_expand,
                                          },
                                          on: {
                                            click: function ($event) {
                                              packageForm.is_expand =
                                                !packageForm.is_expand
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            packageForm.is_expand
                              ? _c(
                                  "div",
                                  { staticClass: "info-body" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "outbound-info" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "outbound-code-form-" + idx,
                                            refInFor: true,
                                            staticClass: "sign-code-form",
                                            attrs: { "label-width": "80px" },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-input",
                                                attrs: {
                                                  label: "出库单",
                                                  prop: "warehouse_record_code",
                                                },
                                              },
                                              [
                                                packageForm.warehouse_record_code
                                                  ? _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          underline: false,
                                                          href: `/warehouse/stock-out/scan?outbound_code=${packageForm.warehouse_record_code}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            packageForm.warehouse_record_code
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [_vm._v("-")]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-input",
                                                attrs: {
                                                  label: "箱子编号",
                                                  prop: "box_code",
                                                },
                                              },
                                              [
                                                packageForm.box_code
                                                  ? _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          underline: false,
                                                          href: `/warehouse/stock-out/box?box_code=${packageForm.box_code}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            packageForm.box_code
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [_vm._v("-")]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form",
                                      {
                                        ref: "sign-code-form-" + idx,
                                        refInFor: true,
                                        staticClass: "sign-code-form",
                                        attrs: {
                                          model: packageForm.package_code,
                                          rules: _vm.validateCodeRule,
                                          "label-width": "80px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "form-item-input",
                                            attrs: {
                                              label: "头程单号",
                                              prop: "feeder_code",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "头程单号",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.emitInputCode(
                                                      idx,
                                                      packageForm.package_code,
                                                      "feeder_code"
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.emitInputCode(
                                                      idx,
                                                      packageForm.package_code,
                                                      "feeder_code"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    packageForm.package_code
                                                      .feeder_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm.package_code,
                                                      "feeder_code",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.package_code.feeder_code",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      slot: "prepend",
                                                      placeholder:
                                                        "请选择运输商",
                                                      filterable: "",
                                                    },
                                                    slot: "prepend",
                                                    model: {
                                                      value:
                                                        packageForm.package_code
                                                          .feeder_company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          packageForm.package_code,
                                                          "feeder_company",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "packageForm.package_code.feeder_company",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.expressCompany,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "form-item-input",
                                            attrs: {
                                              label: "末端单号",
                                              prop: "express_code",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "末端单号",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.emitInputCode(
                                                      idx,
                                                      packageForm.package_code,
                                                      "express_code"
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.emitInputCode(
                                                      idx,
                                                      packageForm.package_code,
                                                      "express_code"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    packageForm.package_code
                                                      .express_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm.package_code,
                                                      "express_code",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.package_code.express_code",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      slot: "prepend",
                                                      placeholder:
                                                        "请选择运输商",
                                                      filterable: "",
                                                    },
                                                    slot: "prepend",
                                                    model: {
                                                      value:
                                                        packageForm.package_code
                                                          .express_company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          packageForm.package_code,
                                                          "express_company",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "packageForm.package_code.express_company",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.expressCompany,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form",
                                      {
                                        ref: "package-attr-form",
                                        refInFor: true,
                                        staticClass: "info-form",
                                        attrs: {
                                          id: "package-attr-form-" + idx,
                                          model: _vm.packageForms[idx],
                                          rules: _vm.validateAttrRule,
                                          "label-width": "80px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "form-item-input",
                                            attrs: {
                                              label: "重量",
                                              prop: "weight",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  disabled: _vm.isWorkMode(idx),
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                model: {
                                                  value: packageForm.weight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm,
                                                      "weight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.weight",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("千克")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "package-size",
                                            attrs: {
                                              label: "尺寸",
                                              prop: "size",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  id: "size-length-" + idx,
                                                  type: "number",
                                                  min: "0",
                                                  disabled: _vm.isWorkMode(idx),
                                                  placeholder: "cm",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    packageForm.size.length,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm.size,
                                                      "length",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.size.length",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "prepend" },
                                                  [_vm._v("长")]
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  disabled: _vm.isWorkMode(idx),
                                                  placeholder: "cm",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                model: {
                                                  value: packageForm.size.width,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm.size,
                                                      "width",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.size.width",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "prepend" },
                                                  [_vm._v("宽")]
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  disabled: _vm.isWorkMode(idx),
                                                  placeholder: "cm",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.getExpress(idx)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    packageForm.size.height,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm.size,
                                                      "height",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.size.height",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "prepend" },
                                                  [_vm._v("高")]
                                                ),
                                              ],
                                              2
                                            ),
                                            _vm.packageData.status_pay == 2
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "info",
                                                      plain: "",
                                                      icon: "el-icon-place",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setMinimumSize(
                                                          idx
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 最小尺寸 ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "cargo-type",
                                            class: {
                                              "show-all-cargo-type":
                                                packageForm.is_show_all,
                                            },
                                            attrs: {
                                              prop: "cargo_types",
                                              label: "货物特性",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                class: {
                                                  "show-all-cargo-type":
                                                    packageForm.is_show_all,
                                                },
                                                attrs: {
                                                  disabled: _vm.isWorkMode(idx),
                                                  size: "small",
                                                },
                                                model: {
                                                  value:
                                                    packageForm.cargo_types,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm,
                                                      "cargo_types",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.cargo_types",
                                                },
                                              },
                                              _vm._l(
                                                _vm.cargoTypes,
                                                function (item, _idx) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: _idx,
                                                      attrs: {
                                                        label: item.value,
                                                        border: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.getExpress(
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.label))]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                            _c(
                                              "el-divider",
                                              {
                                                staticClass:
                                                  "show-more-cargo-type",
                                                on: {
                                                  click: function ($event) {
                                                    packageForm.is_show_all =
                                                      !packageForm.is_show_all
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      packageForm.is_show_all
                                                        ? "收起"
                                                        : "显示更多"
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.formPlugins["duties"]
                                          ? _c(
                                              "el-form-item",
                                              {
                                                staticClass: "is-required",
                                                attrs: { label: "清关信息" },
                                              },
                                              [
                                                _c("b-products", {
                                                  attrs: {
                                                    data: {
                                                      packageAttrProducts:
                                                        packageForm.products ||
                                                        [],
                                                      statusPay:
                                                        _vm.packageData
                                                          .status_pay,
                                                      packageProducts:
                                                        _vm.packageData
                                                          .products,
                                                    },
                                                  },
                                                  on: {
                                                    changeProductsData:
                                                      function ($event) {
                                                        return _vm.changeProductsData(
                                                          $event,
                                                          idx
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "el-form-item",
                                              {
                                                staticClass: "form-item-input",
                                                attrs: {
                                                  label: "报关名",
                                                  prop: "custom_name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    id: "custom-name-" + idx,
                                                    placeholder: "海关报关名",
                                                    disabled:
                                                      _vm.isWorkMode(idx),
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.getExpress(idx)
                                                    },
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.getExpress(idx)
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      packageForm.custom_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        packageForm,
                                                        "custom_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "packageForm.custom_name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        !_vm.formPlugins["duties"]
                                          ? _c(
                                              "el-form-item",
                                              _vm._l(
                                                _vm.customNames,
                                                function (item, _idx) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: _idx,
                                                      attrs: { size: "small" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.inputCustomName(
                                                            idx,
                                                            item.value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(item.value)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form",
                                      {
                                        ref: "form",
                                        refInFor: true,
                                        staticClass: "info-form",
                                        attrs: {
                                          id: "package-op-form-" + idx,
                                          model: _vm.packageForms[idx],
                                          rules: _vm.validateOpRule,
                                          "label-width": "80px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "线路",
                                              prop: "express_id",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled: _vm.isWorkMode(idx),
                                                  "value-key": "id",
                                                  dataCursor:
                                                    _vm.expressList[idx],
                                                  loading: _vm.isExpressLoading,
                                                  "no-data-text": "无匹配线路",
                                                  placeholder: "请选择线路",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.selectExpress(
                                                      idx
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: packageForm.express_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      packageForm,
                                                      "express_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "packageForm.express_id",
                                                },
                                              },
                                              _vm._l(
                                                _vm.expressList[idx],
                                                function (item, i) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: item.id + "-" + i,
                                                      attrs: {
                                                        label: item.title,
                                                        value: item.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " [" +
                                                          _vm._s(item.id) +
                                                          "]" +
                                                          _vm._s(item.title) +
                                                          " " +
                                                          _vm._s(
                                                            item.rate_price
                                                          ) +
                                                          "元 "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "打包服务" } },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                disabled:
                                                  _vm.packageData.status_pay ==
                                                    1 ||
                                                  !_vm.packageData
                                                    .is_packaging_service,
                                                "active-color": "#13ce66",
                                              },
                                              model: {
                                                value:
                                                  packageForm.packagingService,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    packageForm,
                                                    "packagingService",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "packageForm.packagingService",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "总运费" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                packageForm.express
                                                  ? packageForm.express
                                                      .rate_price
                                                  : "-"
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "计费重量" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getRateWeight(
                                                  packageForm.express,
                                                  packageForm.rate_weight
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "货架位置" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                packageForm.shelf_code || "-"
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "建议货架" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.shelfCodeMap[
                                                  packageForm.package_attr_num
                                                ] || "-"
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "上传图片" } },
                                          [
                                            _c(
                                              "el-upload",
                                              {
                                                class: {
                                                  "upload-btn": true,
                                                  disabled:
                                                    _vm.packageData
                                                      .status_pay != 2,
                                                },
                                                attrs: {
                                                  action: "#",
                                                  disabled: _vm.isWorkMode(idx),
                                                  drag: true,
                                                  multiple: true,
                                                  accept:
                                                    "image/x-png,image/gif,image/jpeg,image/svg",
                                                  "show-file-list": false,
                                                  "http-request": (file) =>
                                                    _vm.uploadFile(
                                                      packageForm,
                                                      file
                                                    ),
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-upload__text",
                                                  },
                                                  [_vm._v("点击/拖拽上传")]
                                                ),
                                              ]
                                            ),
                                            packageForm.package_attr_id
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "print-package-btn",
                                                    attrs: { type: "item" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.refreshPhotos(
                                                          _vm.packageForms[idx]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-refresh-right",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.packageData.status_pay == 2 &&
                                            _vm.imageList.length
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      content: "查看原包裹图片",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass:
                                                          "print-package-btn",
                                                        attrs: { type: "item" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showPackageImages(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-plus",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "ul",
                                              { staticClass: "file-list" },
                                              _vm._l(
                                                packageForm.fileList,
                                                function (item, index) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: index,
                                                      staticClass: "item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "top" },
                                                        [
                                                          item.url
                                                            ? _c("el-image", {
                                                                staticClass:
                                                                  "img",
                                                                attrs: {
                                                                  "preview-src-list":
                                                                    packageForm.fileList.map(
                                                                      (e) =>
                                                                        e.url
                                                                    ),
                                                                  src: item.url,
                                                                  fit: "cover",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-close",
                                                            class: {
                                                              disabled:
                                                                _vm.packageData
                                                                  .status_pay !=
                                                                2,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onRemoveImage(
                                                                  packageForm,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "bottom",
                                                        },
                                                        [
                                                          item.percent != 100
                                                            ? _c(
                                                                "el-progress",
                                                                {
                                                                  attrs: {
                                                                    percentage:
                                                                      item.percent ||
                                                                      0,
                                                                    "show-text": false,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm.canAddPackage()
                        ? _c(
                            "div",
                            {
                              staticClass: "new-package-btn",
                              on: { click: _vm.addPackageForm },
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _vm._v(" 添加包裹 "),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              this.packageData.status != 0
                ? _c("div", { staticClass: "ctrl-btn" }, [
                    _c("div", { staticClass: "total" }, [
                      _vm._v("包裹数量：" + _vm._s(_vm.packageForms.length)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "btn" },
                      [
                        _c(
                          "z-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.savePackage },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.packageData.status_pay == 1
                                  ? "提交单号"
                                  : "提交测量信息"
                              )
                            ),
                          ]
                        ),
                        _vm.isShowShowTemporarySaveBtn
                          ? _c(
                              "z-button",
                              {
                                staticClass: "temp-btn",
                                on: { click: _vm.savePackageTemporary },
                              },
                              [_vm._v("暂存数据")]
                            )
                          : _vm._e(),
                        _vm.packageData.status_pay == 2
                          ? _c(
                              "z-button",
                              {
                                staticClass: "temp-btn",
                                attrs: { type: "primary", plain: "" },
                                on: { click: _vm.openProductBarCodeDialog },
                              },
                              [_vm._v("录入商品条码")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isEmpty && this.searchForm.logisticsCode
        ? _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: {
                src: "https://res-cn.public.gblinker.com/gblinker/ops_assets/img_no_result.svg",
              },
            }),
            _c("p", { staticClass: "message" }, [_vm._v("未查询到此包裹")]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "qrcode-dialog",
            visible: _vm.dialog.visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "img" },
            [_c("el-image", { attrs: { src: _vm.packageMiniQrCode } })],
            1
          ),
          _c("p", { staticClass: "tip" }, [
            _vm._v("复制/保存二维码，发送给客户扫描支付即可"),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "router-dialog",
          attrs: { width: "800px", visible: _vm.routerDialog.visible },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.routerDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(_vm._s(_vm.routerDialog.code))]
          ),
          _c(
            "div",
            { staticClass: "router-list" },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.routerDialog.router, function (item, idx) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: idx,
                      attrs: { timestamp: item.time, placement: "top" },
                    },
                    [
                      _c("p", [_vm._v(_vm._s(item.event))]),
                      item.location
                        ? _c("p", { staticClass: "router-item-name" }, [
                            _vm._v(" " + _vm._s(item.location) + " "),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建工单",
            visible: _vm.ticketDialog.visible,
            "close-on-click-modal": false,
            center: "",
            "custom-class": "ticket-dialog",
            "before-close": _vm.closeTicketDialog,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ticketDialog, "visible", $event)
            },
          },
        },
        [
          _c("BCreateTicket", {
            attrs: {
              "user-code": _vm.ticketDialog.userCode,
              codes: _vm.ticketDialog.codes,
              "package-num": _vm.ticketDialog.packageNum,
            },
            on: { createTicketSuccess: _vm.closeTicketDialog },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择图片",
            visible: _vm.imageDrawerVisible,
            size: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imageDrawerVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "el-drawer-image-list" },
            _vm._l(_vm.imageList, function (item, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "image-item" },
                [
                  _c("el-checkbox", {
                    on: {
                      change: function ($event) {
                        return _vm.onChange(item)
                      },
                    },
                    model: {
                      value: item.selected,
                      callback: function ($$v) {
                        _vm.$set(item, "selected", $$v)
                      },
                      expression: "item.selected",
                    },
                  }),
                  _c("el-image", {
                    attrs: {
                      src: item.url,
                      "preview-src-list": _vm.imageList.map((e) => e.url),
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-table",
    {
      staticClass: "product-table",
      attrs: { data: _vm.productForm.products || [], "max-height": "260" },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "name_cn", label: "报关名", width: "240" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: `products.${scope.$index}.name_cn`,
                      rules: _vm.validateAttrRule.name_cn,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          "allow-create": "",
                          filterable: "",
                          clearable: "",
                          size: "small",
                          disabled: _vm.statusPay != 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleSelectProduct($event, scope)
                          },
                        },
                        model: {
                          value: scope.row.name_cn,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "name_cn", $$v)
                          },
                          expression: "scope.row.name_cn",
                        },
                      },
                      _vm._l(_vm.packageProducts, function (item, _index) {
                        return _c("el-option", {
                          key: _index,
                          attrs: { label: item.name_cn, value: item.name_cn },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "数量(件)", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ row, $index }) {
              return [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: `products.${$index}.quantity`,
                      rules: _vm.validateAttrRule.quantity,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        size: "small",
                        min: "1",
                        disabled: _vm.statusPay != 2,
                        clearable: "",
                      },
                      on: { blur: _vm.getPackageRate },
                      model: {
                        value: row.quantity,
                        callback: function ($$v) {
                          _vm.$set(row, "quantity", $$v)
                        },
                        expression: "row.quantity",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "price", label: "单价(RMB)", width: "130" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: `products.${scope.$index}.price`,
                      rules: _vm.validateAttrRule.price,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        size: "small",
                        min: "1",
                        disabled: _vm.statusPay != 2,
                        clearable: "",
                      },
                      on: { blur: _vm.getPackageRate },
                      model: {
                        value: scope.row.price,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "price", $$v)
                        },
                        expression: "scope.row.price",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "price", label: "总价(RMB)", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("el-input", {
                  attrs: {
                    type: "text",
                    size: "small",
                    disabled: "",
                    clearable: "",
                    value: scope.row.quantity * scope.row.price || 0,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-table-column",
        {
          attrs: { prop: "ctrl", label: "操作", width: "180" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _vm.statusPay == 2
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            size: "mini",
                            icon: "el-icon-delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removePackageAttrProductForm(scope)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _vm.statusPay == 2
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        plain: "",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.addPackageAttrProductForm },
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
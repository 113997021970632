var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "search form" },
    [
      _c(
        "el-form",
        {
          ref: "ticket-form",
          attrs: {
            model: _vm.form,
            "label-width": "120px",
            rules: _vm.validateForm,
          },
          on: { submit: () => {} },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "工单分组", prop: "group" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择分组" },
                  model: {
                    value: _vm.form.group,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "group", $$v)
                    },
                    expression: "form.group",
                  },
                },
                _vm._l(_vm.taskGroup, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "异常类别", prop: "exception_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择异常类别" },
                  model: {
                    value: _vm.form.exception_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "exception_type", $$v)
                    },
                    expression: "form.exception_type",
                  },
                },
                _vm._l(_vm.exceptionType, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户编码" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户编码" },
                on: { input: _vm.onUserCodeInput },
                model: {
                  value: _vm.form.user_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_code", $$v)
                  },
                  expression: "form.user_code",
                },
              }),
              _vm.form.user_code && !_vm.userInfo.mobile
                ? _c("span", { staticClass: "red" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(" 用户不存在 "),
                  ])
                : _vm._e(),
              _vm.userInfo.mobile
                ? _c("span", { staticClass: "success" }, [
                    _c("i", { staticClass: "el-icon-circle-check" }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否通知客户" } },
            [
              _c("template", { slot: "label" }, [
                _c(
                  "span",
                  [
                    _vm._v(" 是否通知客户 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "当工单状态发生改变时是否给用户发送通知",
                          placement: "top",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-info" })]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-switch", {
                model: {
                  value: _vm.form.is_notify,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "is_notify", $$v)
                  },
                  expression: "form.is_notify",
                },
              }),
            ],
            2
          ),
          _vm.form.is_notify
            ? _c(
                "el-form-item",
                { attrs: { label: "通知方式" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.notify_method,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "notify_method", $$v)
                        },
                        expression: "form.notify_method",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "sms" } }, [
                        _vm._v("短信通知"),
                      ]),
                      _c(
                        "el-checkbox",
                        { attrs: { label: "office_account", disabled: "" } },
                        [_vm._v("公众号通知")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.is_notify
            ? _c(
                "el-form-item",
                { attrs: { label: "用户手机号" } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "span",
                      [
                        _vm._v(" 用户手机号 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "当工单状态发生改变时会给用户发送短信通知",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-info" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入手机号" },
                      model: {
                        value: _vm.form.mobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mobile", $$v)
                        },
                        expression: "form.mobile",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "phone-prefix",
                          attrs: { slot: "prepend", placeholder: "请选择" },
                          on: { change: _vm.onPhonePrefixChange },
                          slot: "prepend",
                          model: {
                            value: _vm.form.phone_prefix,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone_prefix", $$v)
                            },
                            expression: "form.phone_prefix",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "中国大陆", value: "+86" },
                          }),
                          _c("el-option", {
                            attrs: { label: "美国", value: "+1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "加拿大", value: "001" },
                          }),
                          _c("el-option", {
                            attrs: { label: "澳大利亚", value: "+61" },
                          }),
                          _c("el-option", {
                            attrs: { label: "英国", value: "+44" },
                          }),
                          _c("el-option", {
                            attrs: { label: "德国", value: "+49" },
                          }),
                          _c("el-option", {
                            attrs: { label: "意大利", value: "+39" },
                          }),
                          _c("el-option", {
                            attrs: { label: "法国", value: "+33" },
                          }),
                          _c("el-option", {
                            attrs: { label: "西班牙", value: "+34" },
                          }),
                          _c("el-option", {
                            attrs: { label: "波兰", value: "+48" },
                          }),
                          _c("el-option", {
                            attrs: { label: "奥地利", value: "+43" },
                          }),
                          _c("el-option", {
                            attrs: { label: "日本", value: "+81" },
                          }),
                          _c("el-option", {
                            attrs: { label: "新加坡", value: "+65" },
                          }),
                          _c("el-option", {
                            attrs: { label: "其他", value: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "工单内容", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 6 },
                  placeholder: "请输入工单内容",
                },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "相关单号" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 3, maxRows: 6 },
                  placeholder: "GLP包裹单号/快递单号/订单号等;每行一个",
                },
                model: {
                  value: _vm.form.numbers,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numbers", $$v)
                  },
                  expression: "form.numbers",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "ctrl" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createTicket } },
                [_vm._v("立即创建")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }